export const clothingPersonalizedContent = {
  backgroundImg: "bgImg-clothing.png",
  arrowImage: "firstArrowImg-clothing.png",
  supportImage: "howImg-clothing.png",
  simulatorImage: "roupas-simulador.png",
  lastArrowImage: "lastArrowImg-clothing.png",
  titleText: "Crédito em grupo para vendedores de roupas!",
  titleColor: "#135434",
  textColor: "#2C2D2C",
  carrousselImg: "carrouselImgClothing.png",
  supportText: "Na Veriza temos crédito especial para quem vende roupas.",
};